.header {
  position: fixed;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  transition: all 0.3s ease;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(4px);
  border-radius: 200px;
  width: auto;
  min-width: 60%;
  height: auto;
  display: flex;
  align-items: center;
}

.header.scrolled {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 70px;
  width: auto;
  transition: transform 0.2s ease;
  opacity: 0.9;
  transform: scale(1.4);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
}

.logo:hover img {
  transform: scale(1.6);
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
}

.nav-list {
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
}

.nav-item {
  position: relative;
}

.nav-link {
  font-size: 1rem;
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative;
  padding: 0.5rem 1rem;
}

.nav-link:hover {
  color: #57e0ff;
}

.underline {
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background-color: #5fb0e7;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover .underline {
  transform: scaleX(1);
}

@media (max-width: 768px) {
  .header {
    top: 1rem;
    padding: 0.5rem;
    min-width: 90%;
  }

  .nav-list {
    gap: 0.4rem;
    width: 140px;
  }

  .nav-link {
    font-size: 0.8rem;
    padding: 0px;
  }

  .logo img {
    height: 40px;
    width: auto;
    transition: transform 0.2s ease;
    opacity: 0.9;
    transform: scale(1.5);
    padding-right: 10px;
  }

  .logo {
    padding: 0px;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.3rem;
  }
}
